import React, { useEffect, useRef } from 'react';

const Xmas = () => {
  const canvasRef = useRef(null);
  const mouse = { x: null, y: null };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const particles = {};
    let particleIndex = 0;
    const particleSettings = {
      particleNum: 5, // Heavy effect
      vy: 3.5,
      vx: Math.random() + 1,
      mlife: 250,
    };

    // Track mouse position
    const handleMouseMove = (event) => {
      mouse.x = event.clientX;
      mouse.y = event.clientY;
    };

    // Reset mouse position when it leaves the screen
    const handleMouseOut = () => {
      mouse.x = null;
      mouse.y = null;
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseout', handleMouseOut);

    // Resize canvas to fill the window
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = -20;
        this.vx = particleSettings.vx;
        this.vy = particleSettings.vy;
        this.size = Math.floor(Math.random() * 3) + 1;
        this.gravity = 0.02;
        if (this.size > 2) {
          this.vy += 0.2;
        } else if (this.size < 2) {
          this.vy -= 0.2;
        }
        particleIndex++;
        particles[particleIndex] = this;
        this.id = particleIndex;
        this.life = 0;
        this.maxLife = particleSettings.mlife;
      }

      draw() {
        if (mouse.x && mouse.y) {
          // Adjust particle movement towards the mouse
          const dx = mouse.x - this.x;
          const dy = mouse.y - this.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 100) {
            const force = (100 - distance) / 100; // Normalize the force
            const angle = Math.atan2(dy, dx);
            this.vx += Math.cos(angle) * force;
            this.vy += Math.sin(angle) * force;
          }
        }

        this.x += this.vx;
        this.y += this.vy;

        if (this.y >= canvas.height) {
          this.vy = 0;
          this.vx = 0;
          this.size += 1 / 10;
        }
        this.vy += this.gravity;
        this.life++;
        if (this.life >= this.maxLife) {
          delete particles[this.id];
        }

        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
        ctx.fill();
      }
    }

    const interval = setInterval(() => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < particleSettings.particleNum; i++) {
        new Particle();
      }
      for (let i in particles) {
        particles[i].draw();
      }
    }, 15);

    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseout', handleMouseOut);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999, // Ensures it's above webpage content if needed
        pointerEvents: 'none', // Allows interaction with underlying webpage
      }}
    />
  );
};

export default Xmas;
